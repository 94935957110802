<template>
	<div class="performance performance--overview">
		<div class="performance__col">
			<number-bars class="number-bars--yellow" :title="`GMEI Portal`" unit="" :numbers="gmei" />
		</div>

	</div>
</template>

<script>
import NumberBars from '@/components/NumberBars'

export default {
	name: 'GlobalImpact',
	components: {
		NumberBars,
	},
	data: () => ({
		gmei: [
			{
				value: 281343,
				label: '2020',
				options: {
					decimalPlaces: 0,
				},
			},
			{
				value: 315927,
				label: '2019',
				options: {
					decimalPlaces: 1,
				},
			},
			{
				value: 335304,
				label: '2018',
				options: {
					decimalPlaces: 1,
				},
			},
		],
	}),
}
</script>

<style scoped lang="scss">

</style>
